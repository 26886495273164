import * as React from 'react'
import { useRouter } from 'next/router'
import useSWR from 'swr'
import * as Config from './config'
import ForecastsClient from '@avcan/utils/api/clients/forecasts'
import * as Products from '@avcan/constants/products'
import useSWRImmutable from 'swr/immutable'

const client = new ForecastsClient(Config.api, Config.key)

export default client

export function useForecast(id) {
    const { locale } = useRouter()
    const key = ['forecasts', id, locale]

    return useSWR(key, () => client.product(id, locale))
}

export function useMetadata() {
    const { locale } = useRouter()
    const key = ['metadata', locale]

    return useSWR(key, () => client.metadata(locale))
}

export function useArchiveForecasts(date) {
    const { locale } = useRouter()
    const dateAsString = date.toISOString()
    const key = ['forecasts', dateAsString, locale]
    const swr = useSWRImmutable(key, () => client.archive(date, locale))

    return {
        ...swr,
        data: React.useMemo(() => {
            return Array.isArray(swr.data)
                ? swr.data.filter(product => Products.KindOfForecast.has(product.type))
                : undefined
        }, [swr.data]),
    }
}
