import { useReport, useForecast } from './Context'
import { InnerHTML } from 'components/misc'
import * as Assets from 'utils/assets'
import * as Seasons from '@avcan/constants/products/offseason/seasons'
import { OFFSEASON } from '@avcan/constants/products'
import styles from './OffSeason.module.css'
import messageFormatter from '@avcan/utils/products/forecasts/messageFormatter'

export default function OffSeason() {
    const { type } = useForecast()
    const { season, message, comment } = useReport()

    if (type !== OFFSEASON) {
        return null
    }

    const { value, display } = season
    const src = Assets.images(IconFilenames.get(value))

    return (
        <div className={styles.Condition}>
            <h2 className={styles.ConditionHeader}>{display}</h2>
            <div className={styles.ConditionIcon}>
                <img title={display} alt={display} src={src} />
            </div>
            <div className={styles.ConditionContent}>
                {/* TODO: Eventually we want to remove the messageFormatter util */}
                <InnerHTML>{messageFormatter(message, season)}</InnerHTML>
            </div>
            <InnerHTML>{comment}</InnerHTML>
        </div>
    )
}

// Constants
const IconFilenames = new Map([
    [Seasons.FALL, 'early_season_icon.svg'],
    [Seasons.SPRING, 'spring_situation_icon.svg'],
    [Seasons.SUMMER, 'summer_conditions_icon.svg'],
])
