import * as Alert from 'components/alert'
import * as Layouts from 'prismic/layouts'
import * as Context from '../Context'

export default function AvalancheQuebecInfo() {
    const document = Context.useDocument('forecast-info-avalanche-quebec')

    return (
        <Alert.Info>
            <Layouts.GenericContent payload={document} />
        </Alert.Info>
    )
}
