import * as React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { useReport } from '../Context'
import { InnerHTML } from 'components/misc'
import styles from './Problem.module.css'

ProblemSet.propTypes = {
    problems: PropTypes.array.isRequired,
}

export default function ProblemSet() {
    const { problems } = useReport()

    if (!Array.isArray(problems) || problems.length === 0) {
        return (
            <h3>
                <FormattedMessage
                    description="Layout product/forecast/problems"
                    defaultMessage="No problems identified."
                />
            </h3>
        )
    }

    return problems.map((problem, index) => (
        <Problem key={problem.type.value} {...problem} counter={index + 1} />
    ))
}

// Utils components
Section.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
}

function Section({ title, children }) {
    return (
        <section className={styles.Container} tabIndex="0">
            <h2 className={styles.Header}>{title}</h2>
            {children}
        </section>
    )
}

Advice.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.string.isRequired,
}

function Advice({ children }) {
    return (
        <div className={styles.Advice}>
            <h3 className={styles.SubHeader}>
                <FormattedMessage defaultMessage="Travel and Terrain Advice" />
            </h3>
            <InnerHTML>{children}</InnerHTML>
        </div>
    )
}

Comment.propTypes = {
    children: PropTypes.string.isRequired,
}

function Comment({ children }) {
    return <InnerHTML className={styles.Comment}>{children}</InnerHTML>
}

Figure.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
}

function Figure({ title, src, alt }) {
    return (
        <figure className={styles.Figure}>
            <figcaption>{title}</figcaption>
            <div>
                <img src={src} alt={alt} />
            </div>
        </figure>
    )
}

// Utils
function Problem({ type, factors, comment, travelAndTerrainAdvice, counter }) {
    const values = {
        name: type.display,
        counter: String(counter),
    }
    const title = (
        <FormattedMessage defaultMessage="Avalanche Problem {counter}: {name}" values={values} />
    )

    return (
        <Section title={title}>
            <FigureSet factors={factors} />
            <Comment>{comment}</Comment>
            {travelAndTerrainAdvice && <Advice>{travelAndTerrainAdvice}</Advice>}
        </Section>
    )
}

function FigureSet({ factors }) {
    const ref = React.useRef()

    React.useEffect(() => {
        const { current } = ref

        if (!current || typeof ResizeObserver !== 'function') {
            return
        }

        const observer = new ResizeObserver(([container]) => {
            window.requestAnimationFrame(() => {
                // Prevents benign error "ResizeObserver loop limit exceeded" from clogging Sentry (via: https://stackoverflow.com/a/50387233/12733208)
                const { width } = container.contentRect
                let span = 2

                if (width > 675) {
                    span = 1
                } else if (width < 300) {
                    span = 4
                }

                current.style.setProperty('--figure-span', span)
            })
        })

        observer.observe(current)

        return () => {
            observer.unobserve(current)
        }
    }, [])

    return (
        <div ref={ref} className={styles.FigureSet}>
            {factors.map(({ type, graphic }) => (
                <Figure key={type.value} title={type.display} src={graphic.url} alt={graphic.alt} />
            ))}
        </div>
    )
}
