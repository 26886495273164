import { useReport } from './Context'

export const TravelAndTerrainAdvice = () => {
    const report = useReport()

    if (!report?.terrainAndTravelAdvice) {
        return null
    }

    return (
        <>
            <ul>
                {report.terrainAndTravelAdvice.map(statement => (
                    <li key={statement}>{statement}</li>
                ))}
            </ul>
        </>
    )
}
