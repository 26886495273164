import { Client, Interceptors } from '../../fetch'
import * as URLUtils from '../../lib/url'
import LOCALE from '@avcan/constants/locales'

export default class ForecastsClient {
    constructor(base, key) {
        const url = URLUtils.buildPath(base, 'forecasts')

        this.client = new Client(url, new Interceptors.CacheKey(), new Interceptors.APIKey(key))
    }
    product(id, locale) {
        return this.get(locale, 'products', id)
    }
    products(locale) {
        return this.get(locale, 'products')
    }
    area(id, locale) {
        return this.get(locale, 'areas', id)
    }
    areas(locale) {
        return this.get(locale, 'areas')
    }
    metadata(locale) {
        return this.get(locale, 'metadata')
    }
    point({ lat, lng }, locale) {
        return this.get([locale, 'products', `point?lat=${lat}&long=${lng}`])
    }
    archive(date, locale) {
        return this.get(locale, 'archive', date.toISOString())
    }
    areasByDate(date, locale) {
        return this.get(locale, `areas?date=${date}`)
    }
    metadataByDate(date, locale) {
        return this.get(locale, `metadata?date=${date}`)
    }
    productsByDate(date, locale) {
        return this.get(locale, `products?date=${date}`)
    }
    pointByDate({ lat, lng }, date = new Date().toISOString(), locale) {
        return this.get([locale, 'products', `point?lat=${lat}&long=${lng}&date=${date}`])
    }
    get(locale = LOCALE, type, idOrDate) {
        return this.client.get([locale, type, idOrDate])
    }
}
