import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { FR, LOCALES, TEXTS } from '@avcan/constants/locales'
import styles from './intl.module.css'

// Utils component to display children only when locale is set to provided locale (value).
// Useful to display warning about product not fully translated.
export function LocaleSwitch({ children, value = FR }) {
    const { locale } = useRouter()

    return value === locale ? children : null
}

LocaleSwitcher.propTypes = {
    className: PropTypes.string,
}

export function LocaleSwitcher({ className }) {
    const { asPath, locale: currentLocale } = useRouter()

    return (
        <nav className={clsx(styles.LocaleSwitcher, className)}>
            {Array.from(LOCALES, locale => (
                <Link
                    key={locale}
                    href={asPath}
                    locale={locale}
                    className={styles.Link}
                    aria-current={locale === currentLocale ? 'page' : null}>
                    {TEXTS.get(locale)}
                </Link>
            ))}
        </nav>
    )
}
