import * as React from 'react'
import { useReport, usePrinting } from '../Context'
import Day from './Day'

export default function DangerRatings() {
    const { dangerRatings } = useReport()
    const printing = usePrinting()

    if (!Array.isArray(dangerRatings)) {
        return null
    }

    return dangerRatings.map(({ date, ratings }, index) => (
        <Day
            key={index}
            date={date}
            {...ratings}
            mountain={printing === true ? false : index === 0}
        />
    ))
}
