import * as Arrays from '@avcan/utils/array'
import { getCldImageUrl } from 'next-cloudinary'
import Image from 'next/image'
import { useRouter } from 'next/router'
import PhotoSwipeLightbox from 'photoswipe/lightbox'
import PropTypes from 'prop-types'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import { DANGER_RATINGS_EXPLAINED_OPENED } from '@avcan/constants/products/mixpanel'
import { Content, Section } from 'components/explanation'
import Panel from 'components/panel'
import Shim from 'components/Shim'
import { useSendTrackEvent } from 'hooks/useSendTrackEvent'
import { StructuredText } from 'prismic/components/base'
import * as Context from './Context'
import { useForecast } from './Context'

import 'photoswipe/style.css'
import styles from './Forecast.module.css'

Footer.propTypes = {
    children: PropTypes.node,
}

export default function Footer({ children }) {
    return (
        <footer className={styles.Footer}>
            {children || (
                <React.Fragment>
                    <DangerRatings />
                    <Disclaimer />
                </React.Fragment>
            )}
        </footer>
    )
}

export function Inbox() {
    return <Prismic uid="forecast-rss-message" />
}

export function Disclaimer({ uid = 'forecast-disclaimer', expanded }) {
    return <Prismic uid={uid} expanded={expanded} />
}

export function Prismic({ uid, expanded }) {
    const documents = Context.useDocuments()
    const { data } = documents.get(uid)

    return (
        <Panel header={data.title} expanded={expanded}>
            <Shim horizontal>
                <StructuredText value={data.body} />
            </Shim>
        </Panel>
    )
}

export function DangerRatings({ expanded }) {
    const { locale } = useRouter()
    const forecast = useForecast()
    const sendTrackEvent = useSendTrackEvent()

    const header = (
        <FormattedMessage defaultMessage="Danger Ratings Explained" description="FX Footer" />
    )

    // Set up Photoswipe Lightbox
    const galleryId = 'NAPADS'
    React.useEffect(() => {
        let lightbox = new PhotoSwipeLightbox({
            gallery: '#' + galleryId,
            children: 'a',
            pswpModule: () => import('photoswipe'),
        })
        lightbox.init()

        return () => {
            lightbox.destroy()
            lightbox = null
        }
    }, [])

    const handleClick = () => {
        sendTrackEvent(DANGER_RATINGS_EXPLAINED_OPENED, {
            forecastId: forecast?.id,
        })
    }

    return (
        <Panel header={header} expanded={expanded} handleClick={handleClick}>
            <Section>
                <Content>
                    <div className="pswp-gallery" id={galleryId} style={style.margin}>
                        {locale === 'fr' ? (
                            <LightboxImage
                                href="https://res.cloudinary.com/avalanche-ca/image/upload/v1701903639/website/NAPADS_fr.png"
                                height={2338}
                                width={3134}
                                alt={'Échelle publique nord-américaine de danger d’avalanche'}
                                galleryId={galleryId}
                            />
                        ) : (
                            <LightboxImage
                                href="https://res.cloudinary.com/avalanche-ca/image/upload/v1702507147/website/NAPADS.png"
                                height={2677}
                                width={3727}
                                alt={'North American Public Avalanche Danger Scale'}
                                galleryId={galleryId}
                            />
                        )}
                    </div>
                </Content>
            </Section>
        </Panel>
    )
}

const style = {
    margin: {
        margin: '1em 0',
    },
}

const LightboxImage = ({ href, width, height, alt, galleryId }) => {
    const srcset = BREAKPOINTS.map(breakpoint => {
        const cldImageUrl = getCldImageUrl(
            { src: href, width: breakpoint, height },
            {
                cloud: {
                    cloudName: 'avalanche-ca',
                },
            }
        )
        return `${cldImageUrl} ${breakpoint}w`
    }).join(', ')

    return (
        <a
            href={href}
            data-pswp-width={width}
            data-pswp-height={height}
            data-pswp-srcset={srcset}
            key={galleryId + '-0'}>
            <Image
                width={width}
                height={height}
                src={href}
                loading="lazy"
                quality={'auto'}
                alt={alt}
                sizes="100vw"
                style={{
                    width: '100%',
                    height: 'auto',
                }}
            />
        </a>
    )
}

const BREAKPOINTS = Arrays.range(300, 3000, 300)
