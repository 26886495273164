import Client from './'
import * as Utils from './utils'
import * as PredicateUtils from './utils/predicates'
import { GENERIC } from '@avcan/prismic/types'

export function get(uid, locale) {
    const predicate = PredicateUtils.uid(GENERIC, uid)

    return Client.queryFirst(predicate, { locale })
}

export async function getEvery(uids, locale) {
    return Utils.getEvery(GENERIC, uids, { locale })
}
