import * as React from 'react'
import { LocaleSwitch, LocaleSwitcher } from 'components/intl'
import { ButtonSet } from 'components/button'
import * as Alert from 'components/alert'
import { GenericContent } from 'prismic/layouts'
import * as Context from '../Context'

export default function LocaleWarning() {
    const report = Context.useReport()
    const document = Context.useDocument('avertissement-previsions-pas-completement-traduites')

    if (report.isFullTranslation === true) {
        return null
    }

    return (
        <LocaleSwitch>
            <Alert.Warning>
                <GenericContent payload={document} />
                <ButtonSet>
                    <LocaleSwitcher />
                </ButtonSet>
            </Alert.Warning>
        </LocaleSwitch>
    )
}
