import * as Ratings from '@avcan/constants/products/forecast/ratings'
import * as Palette from '@avcan/constants/products/forecast/ratings/palette'

export const TextFill = new Map([
    [Ratings.EXTREME, Palette.WHITE],
    [Ratings.HIGH, Palette.BLACK],
    [Ratings.CONSIDERABLE, Palette.BLACK],
    [Ratings.MODERATE, Palette.BLACK],
    [Ratings.LOW, Palette.BLACK],
    [Ratings.NO_RATING, Palette.BLACK],
    [Ratings.NO_FORECAST, Palette.BLACK],
    [Ratings.SPRING, Palette.WHITE],
    [Ratings.EARLY_SEASON, Palette.WHITE],
])

export const BannerFill = new Map([
    [Ratings.EXTREME, Palette.BLACK],
    [Ratings.HIGH, Palette.RED],
    [Ratings.CONSIDERABLE, Palette.ORANGE],
    [Ratings.MODERATE, Palette.YELLOW],
    [Ratings.LOW, Palette.GREEN],
    [Ratings.NO_RATING, Palette.WHITE],
    [Ratings.NO_FORECAST, Palette.WHITE],
    [Ratings.SPRING, Palette.BLUE],
    [Ratings.EARLY_SEASON, Palette.BLUE],
])

export const BannerStroke = new Map([
    [Ratings.EXTREME, Palette.RED],
    [Ratings.HIGH, Palette.BLACK],
    [Ratings.CONSIDERABLE, Palette.BLACK],
    [Ratings.MODERATE, Palette.BLACK],
    [Ratings.LOW, Palette.BLACK],
    [Ratings.NO_RATING, Palette.BLACK],
    [Ratings.NO_FORECAST, Palette.BLACK],
    [Ratings.SPRING, Palette.BLACK],
    [Ratings.EARLY_SEASON, Palette.BLACK],
])
