import { useRef, useEffect } from 'react'

import Shim from 'components/Shim'
import { useTrackSectionTimeOnScreen } from 'hooks/useTrackSectionTimeOnScreen'
import { useReport } from './Context'
import { usePushForecastHistory } from 'stores/MixpanelHistoryStore'

import styles from './Forecast.module.css'

export const Summary = ({ title, titleKey, children }) => {
    const ref = useRef(null)
    useTrackSectionTimeOnScreen(ref)
    const forecast = useReport()
    const { id, dateIssued, forecaster, validUntil, title: fxTitle, highlights } = forecast
    const pushForecastHistory = usePushForecastHistory()

    useEffect(() => {
        const handleMouseMove = event => {
            const rect = ref.current.getBoundingClientRect()
            pushForecastHistory({
                x: event.clientX - rect.left,
                y: event.clientY - rect.top,
                section: titleKey,
                timeStamp: Date.now(),
            })
        }

        const handleMouseLeave = () => {
            pushForecastHistory({
                x: 0,
                y: 0,
                section: titleKey,
                timeStamp: Date.now(),
            })
        }

        const element = ref.current
        if (element) {
            element.addEventListener('mousemove', handleMouseMove)
            element.addEventListener('mouseleave', handleMouseLeave)
        }

        return () => {
            if (element) {
                element.removeEventListener('mousemove', handleMouseMove)
                element.removeEventListener('mouseleave', handleMouseLeave)
            }
        }
    }, [])

    return (
        <section
            ref={ref}
            className={styles.Summary}
            data-forecast-section={titleKey}
            data-event-name={titleKey}
            data-forecast-id={id}
            data-date-issued={dateIssued}
            data-valid-until={validUntil}
            data-forecaster={forecaster}
            data-highlights={highlights}
            data-fx-regions={fxTitle}
            data-forecast={JSON.stringify(forecast)}>
            <div style={stylesObj.header}>
                <h3 style={stylesObj.h3}>{title}</h3>
            </div>
            <Shim horizontal>{children}</Shim>
        </section>
    )
}

const stylesObj = {
    header: {
        backgroundColor: '#e4ebfd',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '1em',
        width: '100%',
        marginBottom: '1em',
        marginTop: '2em',
    },
    h3: {
        color: 'var(--primary)',
        marginTop: '1em',
        marginBottom: '1em',
        fontSize: '0.8rem',
        textTransform: 'uppercase',
        letterSpacing: '0.1em',
    },
}
