import React from 'react'

import { FormattedMessage } from 'react-intl'

import { Item, SidebarLink } from 'components/sidebar'

export const Weather = () => {
    return (
        <Item>
            <SidebarLink href="/weather">
                <FormattedMessage
                    description="FX Sidebar"
                    defaultMessage="Your daily Mountain Weather Forecast"
                />
            </SidebarLink>
        </Item>
    )
}

export const SubmitAMin = () => {
    return (
        <Item>
            <SidebarLink href="/mountain-information-network/submit">
                <FormattedMessage
                    description="FX Sidebar"
                    defaultMessage="Submit a Mountain Information Network Report"
                />
            </SidebarLink>
        </Item>
    )
}

export const Blog = () => {
    return (
        <Item>
            <SidebarLink href="/blogs">
                <FormattedMessage description="FX Sidebar" defaultMessage="Avalanche Canada Blog" />
            </SidebarLink>
        </Item>
    )
}

export const Archive = () => {
    return (
        <Item>
            <SidebarLink href="/forecasts/archive">
                <FormattedMessage description="FX Sidebar" defaultMessage="Forecast Archive" />
            </SidebarLink>
        </Item>
    )
}
